import { AuthConfig } from '@auth0/auth0-angular'

import { Auth0UriChecker } from '../app/core/services/auth0-uri-checker'

export const Auth0Environment: AuthConfig = {
    clientId: 'vbyNPgI9qbyeF9wJocaq25fiGbIIjF24',
    domain: 'login-dev.poweredbyliquid.com',
    redirectUri: 'https://web-dev.poweredbyliquid.com/auth-callback',
    audience: 'https://liquid-api-dv.azurewebsites.net',
    scope: 'read:current_user',
    httpInterceptor: {
        allowedList: [
            {
                uriMatcher: (uri) => {
                    return uri.indexOf('youUp') < 0 && // don't add bearer token if youUp
                        uri.indexOf(environment.apiServicePrefix) < 0
                        && !Auth0UriChecker.isPublicUri(uri)
                },
                tokenOptions: {
                    audience: 'https://liquid-api-dv.azurewebsites.net',
                    scope: 'read:current_user',
                },
            },
        ],
    },
}

export const environment: any = {
    production: false,
    hmr: false,
    host: 'https://web-dev.poweredbyliquid.com',
    stripeSettings: {
        stripe_dash_logout_redirect: 'https://web-dev.poweredbyliquid.com/liquid/organization-settings/getpaid',
        redirect_uri: 'https://web-dev.poweredbyliquid.com/liquid/payments/stripe-finalize',
        stripe_connect_client_id: 'ca_EC4aK0Eu8L1btGpRjHItbpvNfy1FJYMs',
    },
    paymentSettings: {
        ccThreshold: 50,
        hpfUrl: 'https://www.chasepaymentechhostedpay-var.com/hpf/1_1/?uID=',
    },
    plaidSettings: {
        environment: 'sandbox',
        publicKey: '0a46c0f14f4070fc1ab945bc15f69a',
        webhookUrl: 'https://liquid-api-dv.azurewebsites.net/plaid-webhooks',
    },
    liquidApiSettings: {
        apiServicePrefix: 'https://liquid-api-dv.azurewebsites.net',
        orgAvatarPrefix: 'https://liquid-avatars-dv.azureedge.net',
        userAvatarPrefix: 'https://liquid-profiles-dv.azureedge.net',
    },
    liquidIntegratorSettings: {
        qb_link_url: 'https://liquid-integrator-service-dv.azurewebsites.net/QBLogin',
    },
    logging: {
        apiKey: 'f19bc42dcfd0fe715f9f7af24c28acbf',
        consoleBreadcrumbsEnabled: true,
        releaseStage: 'dv',
    },
    support: {
        chat: {
            enabled: false,
        },
    },
    internalEmailAdress: {
        compliance: 'compliance@poweredbyliquid.com',
        onboardingSupport: 'onboardingsetuphelp@poweredbyliquid.com',
        support: 'support@poweredbyliquid.com',
    },
    internalSettings: {
        enableBulkCreateInvoice: true,
        enableBulkOfflineContracts: false,
        enableDashboardWidgets: true,
        enableFullStory: false,
        enabledGoogleAnalytics: [],
        enableInvoicesWithoutClients: true,
        enableHubspot: false,
        enableJpmMicrodeposits: false,
        optionalWorkOrderVendor: false,
        enableGuestClient: false,
        enableContacts: false,
        enableCountersigning: true,
        enableNewWOVendorInvite: true,
        enableNonServiceVendors: true,
        enableZendesk: false,
        enableGuestCheckout: true,
        enableVendorLedFlow: true,
        enableSleekPlan: true,
    },
    liquidStatusUrl: 'https://support.poweredbyliquid.com/hc/',
    liquidUrl: 'https://www.poweredbyliquid.com/',
    googleTagManager: {
        enabled: true,
        id: 'GTM-K8P57NT',
    },
    sleekPlanSettings: {
        productId: '125036131',
    },
}
